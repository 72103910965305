import React from "react"
import cx from "classnames"
import * as styles from "../../styles.module.css"
interface SubtitleProps {
  children: string
}

const SubTitle = ({ children }: SubtitleProps) => {
  const subtitleClasses = cx(
    "text-white font-bold font-barlow sm:text-xl lg:text-3xl leading-none",
    styles.title
  )

  return (
    <>
      <p
        dangerouslySetInnerHTML={{ __html: children }}
        className={subtitleClasses}
      />
    </>
  )
}

export default SubTitle
