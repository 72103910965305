import React from "react"
import Menu from "../../components/Menu"
import StepByStep from "../../components/StepByStep"
import BigNumbers from "../../components/BigNumbers"
import Hero from "../../components/Hero"
import BlockCarousel from "../../components/BlockCarousel"
import CtaContract from "../../components/CtaContract"
import Recommendation from "../../components/Recommendation"
import Footer from "../../components/Footer"
import Timeline from "../../components/Timeline"
import Plans from "../../components/Plans"

import {
  designHeroInfo,
  designCarouselProjectsInfo,
  designRecommendationInfo,
  designRecommendationCards,
  designTimelineInfo,
  designTimelineBadges,
  designTimelineInfoDefault,
  infoBigNumbers,
} from "../../mock/data"

interface Props {
  pageContext: {
    pageName: "design-de-solucoes" | "qualidade-de-software"
  }
}

const PageDesign = ({ pageContext: { pageName } }: Props) => {
  return (
    <>
      <Menu pageName={pageName} />
      <Hero {...designHeroInfo} />
      <BlockCarousel {...designCarouselProjectsInfo} />
      <Timeline
        infoTimeline={designTimelineInfo}
        badges={designTimelineBadges}
        infoProps={designTimelineInfoDefault}
        pageName="design-de-solucoes"
      />
      <StepByStep />
      <Plans pageName="design-de-solucoes" />
      <BigNumbers
        infoBigNumbers={infoBigNumbers}
        pageName="design-de-solucoes"
      />
      <Recommendation
        infoRecommendation={designRecommendationInfo}
        infoCards={designRecommendationCards}
      />
      <CtaContract pageName={pageName} />
      <Footer />
    </>
  )
}
export default PageDesign
