import React from "react"
import ReactPlayer from "react-player"
import * as styles from "../../styles.module.css"
import { IMAGES } from "../../../../images"
import _map from "lodash/map"

interface Props {
  video_url?: string
  image?: string
  image_description?: string
}

const Media = ({ video_url, image, image_description }: Props): JSX.Element => {
  if (image)
    return (
      <>
        {_map(IMAGES, (item, index) => {
          if (item.label === image) {
            return <img key={index} src={item.content.default} alt={image_description} />
          }
        })}
      </>
    )

  return (
    <ReactPlayer
      url={video_url}
      config={{
        youtube: {
          playerVars: { controls: 1 },
        },
      }}
      width="100%"
      height="100%"
      className={styles.videoSize}
    />
  )
}
export default Media
