import React from "react"
import cx from "classnames"

import Badges from "../Badges"
import ServiceCardItem from "../ServiceCardItem"
import {
  designBadgesStepByStep,
  designStepByStepInfo,
  designStepByStepCards,
} from "../../mock/data"
import Button from "../Button"

import * as styles from "./styles.module.css"

import { navigate } from "gatsby"

const StepByStep = () => {
  const containerClass = cx("mx-auto main-container")
  const titleClass = cx(
    "text-white font-bold sm:mb-8 lg:mb-12",
    styles.titleClass
  )
  const containerButtonClass = cx(
    "flex justify-center sm:mt-3 lg:mt-7",
    styles.containerButton
  )

  return (
    <div
      className="bg-blackPearl sm:pt-8 sm:pb-8 lg:pt-16 lg:pb-16"
      id="step-by-step"
    >
      <div className={containerClass}>
        <div className="md:mx-auto md:w-4/5">
          <div className="flex sm:mb-4 lg:mb-6">
            {designBadgesStepByStep.map((item, index) => (
              <div key={index}>
                <Badges icon={item.icon} text={item.text} index={index} />
              </div>
            ))}
          </div>
          <h1 className={titleClass}>{designStepByStepInfo.title}</h1>
        </div>
        <ServiceCardItem infoCard={designStepByStepCards} />
        <div className={containerButtonClass}>
          <Button onClick={() => navigate("/design-de-solucoes/#plans")}>
            {designStepByStepInfo.textButton}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default StepByStep
