import React, { useRef, useState } from "react"
import cx from "classnames"

import Title from "../Hero/components/Title"
import SubTitle from "./components/SubTitle"
import Badges from "../Badges"
import _map from "lodash/map"
import _size from "lodash/size"
import Button from "../Button"
import Media from "./components/Media"
import arrowLeft from "../../images/arrow-left.png"
import arrowRight from "../../images/arrow-right.png"
import { Carousel, Button as Btn, Row, Col } from "antd"
import { CarouselRef } from "antd/lib/carousel"

export interface Props {
  rowReverse?: boolean
  linearGradient?: boolean
  backgroundColor1: string
  backgroundColor2: string
  title: string
  subtitle: string[]
  badge: [
    {
      icon?: string
      text: string
    }
  ]
  media: {
    video_url?: string
    image?: string
    image_description?: string
    active: boolean
  }[]
  button: [
    {
      children: string
      type?: "submit" | "reset" | "button"
      preset:
        | "solidCyan"
        | "outlineCyan"
        | "solidBlackPearl"
        | "outlineBlackPearl"
      onClick: () => void
    }
  ]
}

const BlockCarousel = ({
  linearGradient,
  rowReverse,
  backgroundColor1,
  backgroundColor2,
  badge,
  title,
  subtitle,
  button,
  media,
}: Props): JSX.Element => {
  const carouselTxt = React.createRef<CarouselRef>()
  const carouselMedia = React.createRef<CarouselRef>()
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  let sectionClasses = cx("lg:flex items-center justify-center")

  let style = {
    background: backgroundColor1,
  }
  if (linearGradient && backgroundColor2) {
    style.background = `linear-gradient(to bottom,  ${backgroundColor1} 0%,${backgroundColor2} 100%)`
  }

  const gridClasses = cx("m-auto main-container")
  const heroClasses = cx("py-8 md:flex", {
    "md:flex md:flex-row-reverse": rowReverse,
  })
  const buttonClasses = cx("md:flex mt-10")
  const contentClasses = cx("flex flex-col md:w-5/12 md:justify-center", {
    "md:mr-4": !rowReverse,
    "md:ml-4": rowReverse,
  })
  const audioVisualClasses = cx("mt-6 md:mt-0 md:w-7/12 ", {
    "md:mr-3": rowReverse,
    "md:ml-3": !rowReverse,
  })
  const changeCurrent = (current: number) => {
    setCurrentSlide(current)
    carouselMedia?.current?.goTo(current)
  }

  const arrowBtnStyle = {
    width: "44px",
    height: "44px",
    margin: "0 15px",
    border: "1px solif white",
    borderRadius: "100%",
    alignItems: "center",
  }

  const slideDots = {
    width: "20px",
    height: "20px",
    margin: "15px 5px",
    padding: "4px",
    border: "solid 1px",
    borderRadius: "100%",
    verticalAlign: "top",
  }

  const selectedDot = {
    border: "1px solid #00ffea",
    width: "10px",
    height: "10px",
    borderRadius: "100%",
  }

  return (
    <>
      <section className={sectionClasses} style={style}>
        <div className={gridClasses}>
          <div className={heroClasses}>
            <div className={contentClasses}>
              <div className="flex flex-row">
                {_map(badge, (item, index) => (
                  <Badges
                    text={item.text}
                    key={index}
                    index={index}
                    icon={item.icon}
                  />
                ))}
              </div>
              <div className="sm:mb-3">
                <Title>{title}</Title>
              </div>

              <Carousel
                dots={false}
                effect="fade"
                autoplay
                ref={carouselTxt}
                afterChange={(current) => changeCurrent(current)}
              >
                {_map(subtitle, (text, index) => (
                  <SubTitle key={index}>{text}</SubTitle>
                ))}
              </Carousel>

              <Row justify={"center"}>
                <Col span={24} className="md:text-left sm:text-center">
                  <Btn
                    icon={
                      <img
                        src={arrowLeft}
                        alt="Arrow Left"
                        style={{ marginTop: "-2px" }}
                      />
                    }
                    shape="circle"
                    size={"middle"}
                    style={arrowBtnStyle}
                    type="ghost"
                    onClick={() => carouselTxt?.current?.prev()}
                  />

                  {_map(media, (image, index) => {
                    return (
                      <Btn
                        style={{
                          ...slideDots,
                          borderColor:
                            currentSlide === index ? "#00ffea" : "white",
                        }}
                        className={"mx-2 border-double"}
                        key={index}
                        onClick={() => carouselTxt?.current?.goTo(index)}
                        size={"small"}
                        type={"ghost"}
                      >
                        <div
                          style={
                            currentSlide === index
                              ? { ...selectedDot }
                              : undefined
                          }
                        />
                      </Btn>
                    )
                  })}

                  <Btn
                    icon={
                      <img
                        src={arrowRight}
                        alt="Arrow Right"
                        style={{ marginTop: "-2px" }}
                      />
                    }
                    shape="circle"
                    size={"middle"}
                    style={arrowBtnStyle}
                    type="ghost"
                    onClick={() => carouselTxt?.current?.next()}
                  />
                </Col>
              </Row>

              <div className="hidden md:flex">
                <div className={buttonClasses}>
                  {_map(button, (item, index) => {
                    return index !== _size(button) - 1 ? (
                      <div className="sm:mb-6 md:mb-0 md:mr-6">
                        <Button
                          preset={item.preset}
                          onClick={item.onClick}
                          type={item.type}
                        >
                          {item.children}
                        </Button>
                      </div>
                    ) : (
                      <Button
                        preset={item.preset}
                        onClick={item.onClick}
                        type={item.type}
                      >
                        {item.children}
                      </Button>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className={audioVisualClasses}>
              <Carousel dots={false} effect="fade" ref={carouselMedia}>
                {_map(media, (item, index) => (
                  <Media {...item} key={index} />
                ))}
              </Carousel>
              <div className="md:hidden">
                <div className={buttonClasses}>
                  {_map(button, (item, index) => {
                    return index !== _size(button) - 1 ? (
                      <div className="sm:mb-6 md:mb-0 md:mr-6">
                        <Button
                          preset={item.preset}
                          onClick={item.onClick}
                          type={item.type}
                        >
                          {item.children}
                        </Button>
                      </div>
                    ) : (
                      <Button
                        preset={item.preset}
                        onClick={item.onClick}
                        type={item.type}
                      >
                        {item.children}
                      </Button>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BlockCarousel
